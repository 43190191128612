<template>
<div>
<sports-bar/>
<div class="container-fluid desktop-container">
  <div class="row geoff-bg-main py-3">
    <div class="col-2 position-relative">
      <!-- FOTBALL -->
      <side-sport-bar-countries v-if="isCountriesSideBar" :sport="sport"/>
      <side-sport-bar v-else :sport="sport"/>
    </div>
    <div class="col-7 px-0">
      <div class="router-container">
        <router-view/>
      </div>
    </div>
    <div class="col-3 position-relative">
      <div class="fixed-container111">
        <div class="fixed-content">
          <div v-if="SHOW_CONTROLS">
            <video-stream-control/>
          </div>
          <div class="betslip-container">
            <bet-slip-main/>
          </div>
          <div class="promo-slider-container pt-2">
            <promo-slider />
          </div>
          <div class="promo-slider-container pt-2">
            <corner-tile media-name="gamblingmatters"/>
          </div>
          <div class="promo-slider-container pt-2">
            <corner-tile media-name="furlong"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import BetSlipMain from "@/components/BetSlip/BetSlipMain";
import SportsBar from "@/components/SportsBar";
import SideSportBar from "@/components/SideSportBar";
import SideSportBarCountries from "@/components/SideSportBarCountries";
import PromoSlider from "@/components/PromoSlider";
import CornerTile from "@/components/CornerTile";
import VideoStreamControl from "@/components/VideoStream/VideoStreamControl";
import {mapGetters} from "vuex";

export default {
  name: "BaseSportView",
  components: {SideSportBar, SideSportBarCountries, SportsBar, BetSlipMain, PromoSlider, CornerTile, VideoStreamControl},
  computed: {
    ...mapGetters(['SHOW_CONTROLS']),
    isCountriesSideBar() {
      return this.$route.params?.sportName?.toLowerCase() === 'football'
    }
  },
  data:()=>{
    return{
      sport: null
    }
  },
  watch:{
    '$route.params.sportName'(){
      this.initSport()
    }
  },
  beforeMount() {
    this.initSport()
  },
  methods:{
    initSport(){
      if(this.$route.params.sportName){
        this.sport = this.API.SPORTS.filter(sport=>sport.title.toLowerCase() === this.$route.params.sportName.toLowerCase())[0]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.desktop-container{
  // margin-top: 8.5rem;
  margin-top: calc(var(--geoff-header-height) + 4rem); // header + sports bar
  background-color: #FFFFFF;
}
.router-container{
  position: relative;
  width: 100%;
  min-height: 80vh;
}
/* .betslip-container{
  right: 0;
  position: fixed;
  width: inherit;
  z-index: 100;
} */

.fixed-container {
  right: 0;
  // position: relative;
  position: fixed;
  width: inherit;
  z-index: 100;
  height: calc(100vh - 10rem);

  .fixed-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .betslip-container {
    overflow-y: hidden;
    padding: 0 0.5rem;
  }

}
</style>