<template>
  <div class="corner-tile-content">
    <a class=corner-tile-link :href="mediaItem.linkUrl" target="_blank">
      <div v-if="title" class="corner-tile-title">{{ title }}</div>
      <img 
        :src="require('@/assets/img/media/' + mediaItem.imageName + '')"
      :alt="mediaItem.name" />
    </a>
  </div>
</template>

<script>

export default {
  name: "CornerTile",
  props: {
    mediaName: {
      type: String,
      required: true,
      validator: value => {
        return [
          'blog',
          'gamblingmatters',
          'furlong',
        ].includes(value)
      }
    },
    title: {
      type: String,
      default: null
    },
  },
  computed: {
    mediaItem() {
      return this.API.mediaCorners.find(el => el.name === this.mediaName)
    }
  }

}
</script>

<style scoped lang="scss">
  .corner-tile-content {
    background-color: var(--geoff-bg-section);
    overflow: hidden;
    
    .corner-tile-link {
      color: inherit;
      text-decoration: none;
      display: block;
      position: relative;

      &:hover, 
      &:focus, 
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }

    .corner-tile-title {
      text-align: center;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background: var(--geoff-shade-1);
    }
  }
</style>