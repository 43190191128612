// import {createRouter, createWebHashHistory} from 'vue-router'
import {createRouter, createWebHistory} from 'vue-router'

import store from "../store/index";
import HomeView from '../views/HomeView.vue'
import SportsListView from "@/views/SportsListView";
import TransactionsView from "@/views/TransactionsView";
import TransactionsNewView from "@/views/TransactionsNewView";
import AccountView from "@/views/AccountView";
import SportView from "@/views/SportView";
import RaceEventView from "@/views/RaceEventView";
import MatchEventView from "@/views/MatchEventView";
import AntepostEvent from "@/components/Sport/Races/AntepostEvent";
import LoginView from "@/views/auth/LoginView";
import RegistrationView from "@/views/auth/RegistrationView";
import ResetPasswordView from "@/views/auth/ResetPasswordView";
import AccountProfileView from "@/views/account/AccountProfileView";
import ChangePasswordView from "@/views/account/ChangePasswordView";
import DeactivateAccountView from "@/views/account/DeactivateAccountView";
import UpdateAccountView from "@/views/account/UpdateAccountView";
import UserPreferencesView from "@/views/account/UserPreferencesView";
import UserActivityView from "@/views/account/UserActivityView";
import AccountBaseView from "@/views/account/AccountBaseView";
import AccountVerificationView from "@/views/account/AccountVerificationView";
import AccoutOverviewView from "@/views/account/AccoutOverviewView";
import ReactivateAccountView from "@/views/account/ReactivateAccountView";
import InfoPageView from "@/views/InfoPageView";
import responsibleGamblingView from "@/views/account/ResponsibleGamblingView";
import CardDepositView from "@/views/payment/CardDepositView";
import DepositResult from "@/views/payment/DepositResult";
import PhoneDepositView from "@/views/payment/PhoneDepositView";
// import UpdateDepositLimitVIew from "@/views/payment/UpdateDepositLimitVIew";
import UpdateDepositView from "@/views/payment/UpdateDepositView";
import WithdrawalView from "@/views/payment/WithdrawalView";
// import SignUpFormStackView from "@/views/auth/SignUpFormStackView";
import RegisterView from "@/views/auth/RegisterView";
// import SignUpFormStackViewAffiliate from "@/views/auth/SignUpFormStackViewAffiliate";
// import SignUpFormStackViewTwitter from "@/views/auth/SignUpFormStackViewTwitter";
import PromosView from "@/views/PromosView";
import PromoDetail from "@/views/PromoDetailView";
import BaseMenuView from "@/views/BaseMenuView";
import BaseDescktopView from "@/views/BaseDescktopView";
import BaseAccountDesktopView from "@/views/BaseAccountDesktopView";
import SportBaseView from "@/views/BaseSportView";
import TestSafeAreasView from "@/views/TestSafeAreasView";

import { getSportByTitle } from '@/http/api';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 1024

let routes = []

if (isMobile){
    routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
    // not available
    // meta: {
    //   title: 'Register '
    // }
  },
  {
    path: '/sign-up-affiliate',
    name: 'signupaffiliate',
    component: RegisterView,
    meta: {
      title: 'Register',
      referral: 'Affiliate'
    }
  },
  {
    path: '/sign-up-twitter',
    name: 'signuptwitter',
    component: RegisterView,
    meta: {
      title: 'Register',
      referral: 'Twitter'
    }
  },
  {
    path: '/sign-up-sharpbetting',
    name: 'signuptwitter',
    component: RegisterView,
    meta: {
      title: 'Register',
      referral: 'Sharpbetting'
    }
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: RegisterView,
    meta: {
      title: 'Register',
      referral: 'Default'
    }
  },
  {
    path: '/',
    name: 'base',
    component: isMobile?BaseMenuView:BaseDescktopView,
    children: [
     {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
          title: 'Home'
        }
      },
      {
        path: '/test_safe_area',
        component: TestSafeAreasView,
      },
      {
        path: '/promos',
        name: 'promos',
        component: PromosView,
        meta: {
          title: 'Offers & Promotions'
        }
      },
      {
        path: '/promotions/:page',
        name: 'promo',
        component: PromoDetail,
        meta: {
          isPromoDetailTitle: true,
        }
      },
      {
        path: '/sports',
        name: 'sports',
        component: SportsListView,
        meta: {
          title: 'Sports'
        }
      },
      {
        path: '/sport/:sportName',
        name: 'sport',
        component: SportView,
        meta: {
          isSportTitle: true,
        }
      },
      {
        path: '/info/:infoType',
        name: 'info',
        component: InfoPageView,
        meta: {
          isInfoDetailTitle: true,
        }
      },
      {
        path: '/sport/:sportName/race/:ef/:eg',
        name: 'raceEvent',
        component: RaceEventView
      },
      {
        path: '/sport/:sportName/match/:ef/:eg',
        name: 'matchEvent',
        component: MatchEventView
      },
      {
        path: '/antepost/:sportName/:ef/:eg',
        name: 'antepostEvent',
        component: AntepostEvent
      },
      {
        path: '/reset_password',
        name: 'resetPassword',
        component: ResetPasswordView
      },
      {
        path: '/transactions',
        name: 'transactions',
        meta: {requiresAuth:true},
        component: TransactionsNewView
      },
      {
        path: '/transactions-old',
        name: 'transactionsOld',
        meta: {requiresAuth:true},
        component: TransactionsView
      },
      {
        path: '/payment/deposit',
        name: 'deposit',
        component: CardDepositView,
        meta: {
          requiresAuth:true,
          title: 'Deposit'
        },
      },
      {
        path: '/payment/deposit/:provider',
        name: 'deposit-result',
        component: DepositResult,
        meta: {
          requiresAuth:true,
          title: 'Deposit'
        }
      },
      {
        path: '/payment/deposit-phone',
        name: 'depositByPhone',
        component: PhoneDepositView,
        meta: {
          requiresAuth:true,
          title: 'Deposit by Phone'
        },

      },
      //{
      //  path: '/payment/deposit',
      //  name: 'updateDepositLimit',
      //  meta: {requiresAuth:true},
      //  component: UpdateDepositLimitVIew,
      //},
      {
        path: '/payment/update-deposit',
        name: 'updateDepositLimit',
        meta: {requiresAuth:true},
        component: UpdateDepositView,
      },
      {
        path: '/payment/withdrawal',
        name: 'withdrawal',
        meta: {requiresAuth:true},
        component: WithdrawalView,
      },
      {
        path: '/account',
        component: isMobile?AccountBaseView:BaseAccountDesktopView,
        children: [
          {path: '', name: 'account', component: AccountView},
          {path: 'profile', name: 'profile', component: AccountProfileView, meta: {requiresAuth:true}},
          {path: 'update', name: 'updateProfile', component: UpdateAccountView, meta: {requiresAuth:true}},
          {path: 'user-preferences', name: 'userPreferences', component: UserPreferencesView, meta: {requiresAuth:true}},
          {path: 'change_password', name: 'changePassword', component: ChangePasswordView, meta: {requiresAuth:true}},
          {path: 'deactivate', name: 'deactivateProfile', component: DeactivateAccountView},
          {path: 'reactivate', name: 'reactivateProfile', component: ReactivateAccountView},
          {path: 'verification', name: 'accountVerification', component: AccountVerificationView},
          {path: 'responsible-gambling', name: 'responsibleGambling', component: responsibleGamblingView, meta: { requiresAuth: true }},
          {path: 'my-activity', name: 'myActivity', component: UserActivityView, meta: { requiresAuth:true }},
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: isMobile?BaseMenuView:BaseDescktopView
  }
]
} else {
    routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
    // not available
    // meta: {
    //   title: 'Register '
    // }
  },
  {
    path: '/sign-up-affiliate',
    name: 'signupaffiliate',
    component: RegisterView,
    meta: {
      title: 'Register',
      referral: 'Affiliate'
    }
  },
  {
    path: '/sign-up-twitter',
    name: 'signuptwitter',
    component: RegisterView,
    meta: {
      title: 'Register',
      referral: 'Twitter'
    }
  },
  {
    path: '/sign-up-sharpbetting',
    name: 'signuptwitter',
    component: RegisterView,
    meta: {
      title: 'Register',
      referral: 'Sharpbetting'
    }
  },
  {
    path: '/sign-up',
    name: 'signup',
    component: RegisterView,
    meta: {
      title: 'Register',
      referral: 'Default'
    }
  },
  {
    path: '/',
    name: 'base',
    component: isMobile?BaseMenuView:BaseDescktopView,
    children: [
     {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
          title: 'Home'
        }
      },
      {
        path: '/promos',
        name: 'promos',
        component: PromosView,
        meta: {
          title: 'Offers & Promotions'
        }
      },
      {
        path: '/promotions/:page',
        name: 'promo',
        component: PromoDetail,
        meta: {
          isPromoDetailTitle: true,
          // hide right sidebar (promo and betslip) for some info type pages
          hideSidebarPages: ['responsiblegambling']
        }
      },
      {
        path: '/sports',
        name: 'sports',
        component: SportsListView,
        meta: {
          title: 'Sports'
        }
      },
      {
        path: '/info/:infoType',
        name: 'info',
        component: InfoPageView,
        meta: {
          isInfoDetailTitle: true,
          // hide right sidebar (promo and betslip) for some info type pages
          hideSidebarPages: ['responsiblegambling']
        }
      },
    ]
  },
  {
    path: '/sport/:sportName',
    component: SportBaseView,
    
    children:[
      {
        path: '',
        name: 'sport',
        component: SportView,
        meta: {
          isSportTitle: true,
        }
      },
      {
        path: 'race/:ef/:eg',
        name: 'raceEvent',
        component: RaceEventView
      },
      {
        path: 'match/:ef/:eg',
        name: 'matchEvent',
        component: MatchEventView
      },
      {
        path: 'antepost/:ef/:eg',
        name: 'antepostEvent',
        component: AntepostEvent
      },
    ]
  },
  {
    path: '/account',
    component: BaseAccountDesktopView,
    // redirect: {name: 'transactions'},
    children: [
      {
        path: '/transactions',
        name: 'transactions',
        meta: {requiresAuth:true},
        component: TransactionsNewView
      },
      {
        path: '/transactions-old',
        name: 'transactionsOld',
        meta: {requiresAuth:true},
        component: TransactionsView
      },
      {path: '', name: 'account', component: AccoutOverviewView},
      {path: 'profile', name: 'profile', component: AccountProfileView, meta: {requiresAuth:true}},
      {path: 'update', name: 'updateProfile', component: UpdateAccountView, meta: {requiresAuth:true}},
      {path: 'change_password', name: 'changePassword', component: ChangePasswordView, meta: {requiresAuth:true}},
      {path: 'user-preferences', name: 'userPreferences', component: UserPreferencesView, meta: {requiresAuth:true}},
      {path: 'deactivate', name: 'deactivateProfile', component: DeactivateAccountView},
      {path: 'reactivate', name: 'reactivateProfile', component: ReactivateAccountView},
      {path: 'verification', name: 'accountVerification', component: AccountVerificationView},
      {path: 'responsible-gambling', name: 'responsibleGambling', component: responsibleGamblingView, meta: { requiresAuth: true }},
        { path: 'my-activity', name: 'myActivity', component: UserActivityView, meta: { requiresAuth: true } },
      {
        path: '/reset_password',
        name: 'resetPassword',
        component: ResetPasswordView
      },
      {
        path: '/payment/deposit',
        name: 'deposit',
        component: CardDepositView,
        meta: {
          requiresAuth:true,
          title: 'Deposit'
        }
      },
      {
        path: '/payment/deposit/:provider',
        name: 'deposit-result',
        component: DepositResult,
        meta: {
          requiresAuth:true,
          title: 'Deposit'
        }
      },
      {
        path: '/payment/deposit-phone',
        name: 'depositByPhone',
        component: PhoneDepositView,
        meta: {
          requiresAuth:true,
          title: 'Deposit by Phone'
        },

      },
      //{
      //  path: '/payment/deposit',
      //  name: 'updateDepositLimit',
      //  meta: {requiresAuth:true},
      //  component: UpdateDepositLimitVIew,
      //},
      {
        path: '/payment/update-deposit',
        name: 'updateDepositLimit',
        meta: {requiresAuth:true},
        component: UpdateDepositView,
      },
      {
        path: '/payment/withdrawal',
        name: 'withdrawal',
        meta: {requiresAuth:true},
        component: WithdrawalView,
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: isMobile?BaseMenuView:BaseDescktopView
  }
]
}

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    document.getElementById(isMobile?'content':'scroll-content')?.scrollTo(0,0)
    return {
      el:isMobile?'#content':'#scroll-content',
      top: 0
    }
  },
})

router.afterEach((to) => {
  // window.gtag('config', window.GA_TRACKING_ID, {
  //   page_path: to.fullPath,
  //   // app_name: 'Monitive Web App',
  //   send_page_view: true,
  // });
  let pageTitle = to.name
  // page_title = 

  if (['sport', 'matchEvent', 'raceEvent', 'antepostEvent'].includes(to.name) && to.params?.sportName) {
    pageTitle = `${to.name} ${to.params?.sportName}`
  }
  if (['promo'].includes(to.name) && to.params?.page) {
    pageTitle = `${to.name} ${to.params?.page}`
  }
  if (['info'].includes(to.name) && to.params?.infoType) {
    pageTitle = `${to.name} ${to.params?.infoType}`
  }
  console.log('DEBUG router.afterEach: ', pageTitle, to.fullPath)

  window.gtag('event', 'page_view', {
    page_title: pageTitle,
    page_location: to.fullPath
  });
  
});

router.beforeEach((to, from, next) => {
  // set title 
  let titlePrefix = '';
  // static page
  if (to.meta?.title) {
    titlePrefix = to.meta?.title
  }
  // sport name to title
  if (to.meta?.isSportTitle) {
    const sport = getSportByTitle(to.params.sportName)
    if (sport) {
      titlePrefix = sport?.meta?.pageTitle || sport.title
    }
    else {
      titlePrefix = to.params.sportName
    }
  }
  // promo title 
  if (to.meta?.isPromoDetailTitle) {
    switch (to.params?.page) {
      case 'NewAccountRebate': 
        titlePrefix = 'New Account Offer'
        break;
      case 'bog': 
        titlePrefix = 'Best Odds Guaranteed'
        break;
      case 'SecondPlace': 
        titlePrefix = '2nd Place Refund'
        break;
      case 'first10': 
        titlePrefix = '10 Minute Blast'
        break;
      case 'ResponsibleGambling': 
        titlePrefix = 'Responsible Gambling'
        break;
      default:
        titlePrefix = '' // TODO: empty?
        break;
    }
  }
  // info title 
  if (to.meta?.isInfoDetailTitle) {
    switch (to.params?.infoType) {
      case 'Terms And Conditions': 
        titlePrefix = 'T&C'
        break;
      case 'Privacy Policy': 
        titlePrefix = 'Privacy Policy'
        break;
      case 'Help': 
        titlePrefix = 'Help'
            break;
      case 'ResponsibleGambling': 
        titlePrefix = 'Responsible Gambling'
        break;
      default:
        titlePrefix = to.params?.infoType
        break;
    }
  }
  
  document.title =  titlePrefix ? `${titlePrefix} | Geoff Banks Online` : 'Geoff Banks Online'

  // document.title = "Geoff Banks Online"
  let content = document.getElementById('scroll-content')
  if (content) content?.scrollTo(0,0)
  if (to.name === 'not-found') next({name: 'home'})
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next ({name: 'login', query: {from: to.path}})
  } else {
    // if (from.path === '/account' && !isMobile) next('/profile')
    next()
  }
})


export default router
